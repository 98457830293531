import * as React from "react"
import styled from "styled-components"

const ArrowCon = styled.div`
  margin: 0 auto;
  text-align: center;
`

function Chevron2(props) {
  return (
    <ArrowCon>
      <svg
        width={50}
        height={41}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50 21.103L24.162 40.051 0 21.066l2.29-2.914L24.24 35.4l23.569-17.284L50 21.103z"
          fill="#5B1B2B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50 2.988L24.162 21.936 0 2.95 2.29.037l21.95 17.247L47.809 0 50 2.988z"
          fill="#5B1B2B"
        />
      </svg>
    </ArrowCon>
  )
}

export default Chevron2