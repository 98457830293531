import React from 'react'
import styled from 'styled-components'

import HalfWidthCon from '../../Atoms/HalfWidthCon'
import Chevron2 from '../../Atoms/Chevron-2'

const FlexContainer = styled.li`
  margin: 0 auto;
  width: 100%;
  max-width: ${p => p.theme.breakpoints.huge}px;
  padding: 2em 1.5rem;
  display: flex;
  flex-flow: column wrap;
  align-items: center;  
  ${p => p.theme.mediaQueries.ipadUp}{
    padding: 0.5em 2rem;
    flex-flow: row nowrap;
    ${HalfWidthCon}:nth-child(odd){
      align-items: flex-end;
    }
  ${HalfWidthCon}:nth-child(even){
      align-items: flex-start;
    }
  ${p => p.theme.mediaQueries.largeUp}{
    padding: 0.75rem 2.25rem;
    }
`

const Title = styled.h3`
  font-family: ${p => p.theme.font.objectives};
  font-weight: 300;
  color: ${(p) => (p.active ? p.theme.color.white : p.theme.color.burgundy)};
  font-size: 2.75rem;
  text-transform: uppercase;
  text-align: right;
  display: inline;
  white-space: nowrap;
  padding-left: 20px;
  padding-right: 1.5rem;
  margin-bottom: 15px;
  transition: all .5s ease-in-out;
  ${p => p.theme.mediaQueries.ipadUp}{
    font-size: 2rem;
    padding-right: 2rem;
  }
  ${p => p.theme.mediaQueries.mediumUp}{
    font-size: 2.8rem;
  }
  ${p => p.theme.mediaQueries.hugeUp}{
    font-size: 3rem;
  }
`
const Button = styled.a`
  color: ${p => p.theme.color.burgundy};
  font-family: ${p => p.theme.font.body};
  background-color: transparent;
  border: 1px solid ${p => p.theme.color.burgundy};
  padding: 10px 50px; 
  text-transform: lowercase;
  text-decoration: none;
  text-align: center;
  margin-top: 25px;
  width: 250px;
  transition: 0.3s ease-in-out;
  margin: 0 auto;
  cursor: pointer;
  &:hover{
    background-color: ${p => p.theme.color.burgundy};
    color: ${p => p.theme.color.white};
  }
  ${p => p.theme.mediaQueries.ipadUp}{
    width: 300px;
    margin-left: 30px;
  }
`
const ReportButton = styled.a`
  color: ${p => p.theme.color.white};
  font-family: ${p => p.theme.font.body};
  background-color: ${p => p.theme.color.burgundy};
  border: 1px solid ${p => p.theme.color.burgundy};
  padding: 10px 50px; 
  text-transform: lowercase;
  text-decoration: none;
  text-align: center;
  width: 250px;
  margin: 0 auto;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.3s background ease-in-out;
  display: ${(p) => p.showButton ? 'block' : 'none'};
  &:active, &:visited{
    background-color: ${p => p.theme.color.burgundy};
    color: ${p => p.theme.color.white};
  }
  &:hover{
    background-color: transparent;
    color: ${p => p.theme.color.burgundy};
  }
  ${p => p.theme.mediaQueries.ipadUp}{
    width: 300px;
    margin-top: 40px;
  }
`

const MoreButton = styled.div`
  color: ${p => p.theme.color.burgundy};
  font-family: ${p => p.theme.font.body};
  text-transform: lowercase;
  text-decoration: none;
  text-align: center;
  margin: 0 auto;
  padding: 40px;
  top: 0;
  font-weight: bold;
  cursor: pointer;
`

const Line = styled.div`
  border-left: 2px solid ${p => p.theme.color.burgundy};
  height: 100px;
  margin: 20px 0 0 50%;
`

const MoreDiv = styled.div`
  display: ${(p) => p.showButton ? 'block' : 'none'};
`

class MonitoringReports extends React.Component {
  constructor(){
  super();
  this.toggleButton = this.toggleButton.bind(this);
  this.state = {
    active: false,
  }
}
toggleButton = () => {
  this.setState({ active: !this.state.active });
}
render(){
  const progress = this.props.data
return (
    <div>
      <ul>
      {progress.edges.slice(0, this.state.active ? progress.edges.length : 3).map((report, i) =>  
        <FlexContainer data-aos="fade-up" 
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-delay="50" key={i}>
          <HalfWidthCon>
              <Title>{report.node.reportDate}</Title>
          </HalfWidthCon>
          <HalfWidthCon>
              <Button key={i} href={report.node.reportPdf.url} target="_blank" rel="noopener noreferrer"><strong>view</strong> the report</Button>
          </HalfWidthCon>
        </FlexContainer>
        )}
      </ul>
      <MoreDiv data-aos="fade-up" 
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-delay="50" showButton={progress.edges.length <= 3 ? 0 : 1}>
        <Line/>
        <MoreButton active={this.state.active ? 1 : 0} onClick={this.toggleButton}>
          <a>{this.state.active ? 'hide reports' : 'see more reports'}</a>
        </MoreButton> 
        <Chevron2/>   
      </MoreDiv>
    </div>
    )
  }  
}

export default MonitoringReports