import * as React from "react"

function Arrow(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 33 33" {...props}  aria-label="Show/hide content">
      <path
        fill="#fff"
        d="M25.09 21.4l-8.59-7.48-8.6 7.48-1.46-1.68 10.06-8.76 10.06 8.76-1.47 1.68z"
      />
    </svg>
  )
}

export default Arrow

