import React from 'react'
import styled from 'styled-components'

import {Diamond, Star, Triangle} from './Partials'

const IconCon = styled.div`
  margin-right: 15px;
  svg{
    width: 45px;
    height: 45px;
  }
`

const Icon = ({title}) => {
  const symbol = title;
  if (symbol === 'Foundational'){
    return(
      <IconCon>
        <Triangle />
      </IconCon>
    ) 
  }
  if (symbol === 'Transformational'){
    return(
      <IconCon>
        <Diamond />
      </IconCon>
    )
  } 
  if (symbol === 'Aspirational') {
    return(
      <IconCon>
        <Star />
      </IconCon>
    )
  } else {
    return(
      null
    )
  }
}

export default Icon