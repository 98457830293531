import * as React from "react"

function Star(props) {
  return (
    <svg
      id="Star_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 55.36 55.36"
      {...props}
    >
      <defs>
        <style>{".Star_svg__cls-1{fill:#702d3e}"}</style>
      </defs>
      <path
        className="Star_svg__cls-1"
        d="M27.68 35.72l-7.94 4.17 1.52-8.84-6.42-6.25 8.87-1.29 3.97-8.04 3.97 8.04 8.87 1.29-6.42 6.25 1.52 8.84-7.94-4.17z"
      />
      <path
        className="Star_svg__cls-1"
        d="M27.68 55.36a27.68 27.68 0 1127.68-27.68 27.71 27.71 0 01-27.68 27.68zm0-54A26.35 26.35 0 1054 27.68 26.38 26.38 0 0027.68 1.33z"
      />
    </svg>
  )
}

export default Star

