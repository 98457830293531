import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from'gatsby'

import {RandomImage} from '../../../helpers'

import FlexContainer from '../../Atoms/FlexContainer'
import HalfWidthCon from '../../Atoms/HalfWidthCon'
import StyledLine from '../../Atoms/StyledLine'
import Section from '../../Atoms/Section'
import Chevron from '../../Atoms/Chevron'

import Tab from '../../Molecules/Tab'
import MainContent from '../../Molecules/MainContent'
import Quote from '../../Molecules/Quote'

const MissionCon = styled(Section)`
  background: rgb(97,33,49);
  background: linear-gradient(180deg, rgba(97,33,49,1) 0%, rgba(112,45,62,1) 100%);
`

const Mission = () => {
  const data = useStaticQuery(graphql`
  query MissionQuery {
    mission: datoCmsMission{
      sectionTitle
      sectionBodyNode{
        childMarkdownRemark{
          html
        }
      }
      quoteNode{
        childMarkdownRemark{
          html
        }
      }
      attribution
      chevronImage{
        alt
        fluid(maxWidth: 600) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
  `)
  return(
    <MissionCon id="mission">
      <Tab color="pink" text="burgundy" title={data.mission.sectionTitle}/>
      <MainContent text="Cambrian" highlight="intends to lead" br markdown={data.mission.sectionBodyNode.childMarkdownRemark.html}/>
      <FlexContainer>
        <HalfWidthCon data-aos="fade-up" 
          data-aos-duration="1000"
          data-aos-easing="ease-in-out">
          <Quote markdown={data.mission.quoteNode.childMarkdownRemark.html} attribution={data.mission.attribution} quote/>
        </HalfWidthCon>
        <HalfWidthCon data-aos="fade-up" 
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-delay="50">
          <Chevron fluid={RandomImage(data.mission.chevronImage).fluid} alt={RandomImage(data.mission.chevronImage).alt}/>
        </HalfWidthCon>
      </FlexContainer>
      <StyledLine />
    </MissionCon>
  )
}

export default Mission