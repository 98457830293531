import React from 'react'
import styled from 'styled-components'

import LineElement from './Partials/LineElement'

const LineContainer = styled.div`
  text-align: center;
  svg{
    height: 170px;
    path, circle {
      fill: ${p => (p.burgundy ? p.theme.color.burgundy : p.theme.color.gold)};
    }
  }
`

const StyledLine = ({burgundy}) => {
  return(
    <LineContainer burgundy={burgundy} data-aos="fade-up" 
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-delay="50" >
      <LineElement />
    </LineContainer>
  )
}

export default StyledLine