import * as React from "react"

function LineElement(props) {
  return (
    <svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 3.53 168.22"
      {...props}
    >
      <defs>
        <style>{".prefix__cls-2{fill:#ffc427}"}</style>
      </defs>
      <path fill="#ffc53d" d="M1.26 0h1v128.09h-1z" />
      <circle className="prefix__cls-2" cx={1.76} cy={142.18} r={1.76} />
      <circle className="prefix__cls-2" cx={1.76} cy={154.32} r={1.76} />
      <circle className="prefix__cls-2" cx={1.76} cy={166.45} r={1.76} />
    </svg>
  )
}

export default LineElement

