import * as React from "react"

function Triangle(props) {
  return (
    <svg
      id="Triangle_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 55.36 55.36"
      {...props}
    >
      <defs>
        <style>{".Triangle_svg__cls-1{fill:#702d3e}"}</style>
      </defs>
      <path
        className="Triangle_svg__cls-1"
        d="M27.68 35.5H15.55l6.06-9.82 6.07-9.82 6.07 9.82 6.06 9.82H27.68z"
      />
      <path
        className="Triangle_svg__cls-1"
        d="M27.68 55.36a27.68 27.68 0 1127.68-27.68 27.71 27.71 0 01-27.68 27.68zm0-54A26.35 26.35 0 1054 27.68 26.38 26.38 0 0027.68 1.33z"
      />
    </svg>
  )
}

export default Triangle