import React from "react"
import "aos/dist/aos.css";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/Organisms/Hero'
import Mission from '../components/Organisms/Mission'
import Objectives from '../components/Organisms/Objectives'
import Approach from '../components/Organisms/Approach'
import Process from '../components/Organisms/Process'
import Commitment from '../components/Organisms/Commitment'
import Progress from '../components/Organisms/Progress'

class IndexPage extends React.Component {
  componentDidMount() {
    const AOS = require('aos')
    this.aos = AOS
    this.aos.init()
  }

  componentDidUpdate() {
    this.aos.refresh()
  }

  render(){
    return (
      <Layout>
        <SEO title="Further Together" />
        <Hero /> 
        <Mission />
        <Approach />
        <Process />
        <Objectives />
        <Commitment />
        <Progress />
      </Layout>
    )
  }
}

export default IndexPage
