import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import animationData from './farther-together.json'

const LottieCon = styled.div`
  width: 100%;
  max-width: 1750px;
  height: auto;
`

export default function LottieAnimation() {
  const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      },
    };
  
  return (
    <LottieCon>
      <Lottie 
      options={defaultOptions} isClickToPauseDisabled={true} />
    </LottieCon>
  );
}
