import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from'gatsby'

import {RandomImage} from '../../../helpers'

import {Markdown} from '../../Atoms/Typography'
import StyledLine from '../../Atoms/StyledLine'
import Section from '../../Atoms/Section'
import FlexContainer from '../../Atoms/FlexContainer'
import HalfWidthCon from '../../Atoms/HalfWidthCon'
import Chevron from '../../Atoms/Chevron'

import Tab from '../../Molecules/Tab'
import MainContent from '../../Molecules/MainContent'

const CommitmentCon = styled(Section)`
  background: ${p => p.theme.color.gold};
  min-height: 50vh;
`

const Button = styled.a`
  color: ${p => p.theme.color.burgundy};
  font-family: ${p => p.theme.font.body};
  background-color: transparent;
  border: 1px solid ${p => p.theme.color.burgundy};
  padding: 10px 50px; 
  text-transform: lowercase;
  text-decoration: none;
  text-align: center;
  margin-top: 25px;
  width: 300px;
  transition: 0.3s ease-in-out;
  margin: 0 auto;
  &:hover{
    background-color: ${p => p.theme.color.burgundy};
    color: ${p => p.theme.color.white};
  }
  ${p => p.theme.mediaQueries.ipadUp}{
    width: 400px;
    margin-left: 0;
  }
`
const StyledFlexContainer = styled(FlexContainer)`
  padding-top: 0px;
  ${p => p.theme.mediaQueries.smallDown}{
    padding-top: 0px;
  }
`
const Commitment = () => {
  const data = useStaticQuery(graphql`
  query CommitmentQuery {
    commitment: datoCmsCommitment{
      sectionTitle
      sectionBodyNode{
        childMarkdownRemark{
          html
        }
      }
      sectionCalloutNode{
        childMarkdownRemark{
          html
        }
      }
      chevronImage{
        alt
        fluid(maxWidth: 600) {
          ...GatsbyDatoCmsFluid
        }
      }
      pdfDownload{
        buttonText
        pdfFile{
          url
        }
      }
    }
  }
  `)
  return(
    <CommitmentCon id="commitment">
      <Tab text="gold" color="burgundy" title={data.commitment.sectionTitle}/>
      <MainContent text="It will take " highlight="all of us" br markdown={data.commitment.sectionBodyNode.childMarkdownRemark.html} burgundy/>
        <StyledFlexContainer callout>
          <HalfWidthCon data-aos="fade-up" 
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-delay="50">
            <Chevron fluid={RandomImage(data.commitment.chevronImage).fluid} alt={RandomImage(data.commitment.chevronImage).alt}/>
          </HalfWidthCon>
          <HalfWidthCon id="download" data-aos="fade-up" 
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-delay="50">
            <Markdown markdown={data.commitment.sectionCalloutNode.childMarkdownRemark.html} burgundy callout/>
            {data.commitment.pdfDownload.map((pdf, i) =>  
              <Button key={i} href={pdf.pdfFile.url} target="_blank" rel="noopener noreferrer">{pdf.buttonText}</Button>
            )}
          </HalfWidthCon>
        </StyledFlexContainer>
        <StyledLine burgundy />
    </CommitmentCon>
  )
}

export default Commitment