import styled from 'styled-components'

const H2 = styled.h2`
  font-family: ${p => p.theme.font.objectives};
  font-size: 1.375rem;
  color: ${p => p.theme.color.burgundy};
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 500;
`
export default H2