import React from 'react'
import styled, { css } from 'styled-components'
import { StaticQuery, graphql } from'gatsby'

import Container from '../../Atoms/Container'
import ChevronSVG from '../../Atoms/ChevronSVG'

import Objective from '../../Molecules/Objective'
import Tab from '../../Molecules/Tab'

const Gradient = styled.div`
  background: rgb(247,154,96);
  background: linear-gradient(180deg, rgba(247,154,96,1) 0%, rgba(255,195,54,1) 100%);
  padding-top: 20px;
`

const Gold = styled.div`
  background: rgba(255,195,54,1)};
  padding-bottom: 50px;
`
const ObjectivesCon = styled.section`
background-color: #f79a60;
`
const TitleContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  padding-top: 0px;
  padding-bottom: 30px;
  ${p => p.theme.mediaQueries.largeUp}{
    padding-top: 0px;
    padding-bottom: 30px;
  }
  ${p => p.theme.mediaQueries.ipadUp}{
    padding-top: 0px;
    padding-bottom: 30px;
  }
`

const styledTitle = css`
  font-family: ${p => p.theme.font.title};
  color: ${p => p.theme.color.white};
  font-size: 4rem;
  line-height: 90%;
  text-align: right;
  ${p => p.theme.mediaQueries.ipadUp}{
    font-size: 7rem;
  }
  ${p => p.theme.mediaQueries.largeUp}{
    font-size: 10rem;
  }
`
const Title = styled.h2`
  ${styledTitle};
`
const Burgundy = styled.span`
  color: ${p => p.theme.color.burgundy};
`
class Objectives extends React.Component {
  render(){
    const { data } = this.props;

  return(
    <ObjectivesCon id="objectives">
      <Tab text="gold" color="burgundy" title="Our Objectives"/>
      <Gradient>
        <TitleContainer>
          <Title data-aos="fade-up" 
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-delay="50">
            eight 
            <br /> 
            <Burgundy>
            objectives
            </Burgundy>
            <ChevronSVG />
          </Title>
        </TitleContainer>
      </Gradient>
      <Gold>
        <ol>
            {data.allDatoCmsObjective.edges.map((objective, key) => 
              <Objective key={key} objective={objective}/>
            )}
        </ol>
      </Gold>
    </ObjectivesCon>
    );
  }
}

export default props => (
  <StaticQuery 
    query={graphql`
      query ObjectiveQuery {
        allDatoCmsObjective(sort: {fields: position}){
          edges{
            node{
              position
              objectiveTitle
              objectiveDescriptionNode {
                childMarkdownRemark {
                  html
                }
              }
              calloutStatement
              foundationalStatementNode{
                childMarkdownRemark{
                  html
                }
              }
              transformationalStatementNode{
                childMarkdownRemark{
                  html
                }
              }
              aspirationalStatementNode{
                childMarkdownRemark{
                  html
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Objectives data={data}{...props}/>}
  />
);