import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from'gatsby'

import Section from '../../Atoms/Section'
import HalfWidthCon from '../../Atoms/HalfWidthCon'
import Container from '../../Atoms/Container'

import Tab from '../../Molecules/Tab'
import MainContent from '../../Molecules/MainContent'
import MonitoringReports from '../../Molecules/MonitoringReports'

const ProgressCon = styled(Section)`
  background: ${p => p.theme.color.gold};
`

const StyledMainContent = styled(MainContent)`
  padding-bottom: 1rem;
  ${p => p.theme.mediaQueries.ipadUp}{
    padding-bottom: 1rem;
  }
  ${p => p.theme.mediaQueries.largeUp}{
    padding-bottom: 1rem;
  }
`
const Progress = () => {
  const data = useStaticQuery(graphql`
  query progressQuery {
    progress: datoCmsOurProgress{
      sectionTitle
      progressDescriptionNode{
        childMarkdownRemark{
          html
          }
        }
      }
      report: allDatoCmsMonitoringReport(sort: {fields: position}){
        edges{
          node{
            reportDate
            reportPdf {
              url
            }
            position
          }
        }
      }
    }
  `)
  return(  
    <ProgressCon id="progress">
      <Tab text="gold" color="burgundy" title={data.progress.sectionTitle}/>
      <Container>
        <StyledMainContent text="Our" highlight="Progress" br markdown={data.progress.progressDescriptionNode.childMarkdownRemark.html} burgundy/>
        <MonitoringReports data={data.report}/>
      </Container>
    </ProgressCon>
  )
}

export default Progress