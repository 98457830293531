import styled from 'styled-components'

const H3 = styled.h3`
  font-family: ${p => p.theme.font.heading};
  font-size: 2rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: ${p => p.theme.color.white};
  display: block;
  padding: 10px 0;
  color: ${p => (p.burgundy ? p.theme.color.burgundy : p.theme.color.white )};
  ${p => p.theme.mediaQueries.ipadUp}{
    padding: 15px 0 15px;
    text-align: right;
    font-size: 2.25rem;
    // width: 350px;
  }
`
export default H3