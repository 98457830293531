import React from 'react'
import styled from 'styled-components'

const MarkdownContainer = styled.div`
  ${p => p.style};
  flex: 1;
  h1, h2, h3, h4, h5, h6, p{
    font-family: ${p => p.theme.font.body};
  }
  h4{
    color: ${p => p.theme.color.gold};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
    padding-bottom: 5px;
  }
  p{
    font-weight: 400;
    margin-bottom: 1.35rem;
      &:last-child{
        margin-bottom: 15px;
      }
    color: ${p => (p.burgundy ? p.theme.color.burgundy : p.theme.color.white)};
    strong{
      font-weight: 500;
      font-size: 1.125rem;
      em{
        color: ${p => (p.burgundy ? p.theme.color.burgundy : p.theme.color.pink)};
      }
    }
    ${p => (p.quote ? 
      `font-size: 1.25rem;
      font-style: italic;
      text-align: right;
      strong{
        font-size: 1.8rem;
        color: ${p.burgundy ? p.theme.color.burgundy : p.theme.color.gold};
      }
      ` 
      : p.callout ?
      `font-size: 1.875rem;
        strong{
          font-size: 1.875rem;
          font-weight: 600;
        }
      `
      :
      ``
    )};
  }
`
const Markdown = ({ markdown, style, quote, callout, burgundy }) => (
  <MarkdownContainer
    dangerouslySetInnerHTML={{ __html: markdown }}
    style={style} quote={quote ? 1 : 0} burgundy={burgundy ? 1 : 0} callout={callout ? 1 : 0}
  />
)

export default Markdown