import styled, { css } from 'styled-components'

const H1Style = css`
  font-family: ${p => p.theme.font.title};
  font-size: 5rem;
  color: ${p => p.theme.color.white};
  text-align: right;
  font-weight: 900;
  line-height: 1;
  padding-bottom: 40px;
  padding-top: 50px;
  ${p => p.theme.mediaQueries.mediumUp}{
    font-size: 8rem;
  }
  ${p => p.theme.mediaQueries.largeUp}{
    font-size: 10rem;
  }
  ${p => p.theme.mediaQueries.bigUp}{
    font-size: 12rem;
  }
  ${p => p.theme.mediaQueries.hugeUp}{
    font-size: 15rem;
  }
`
const H1 = styled.h1`
  ${H1Style}; 
`
export default H1