//Container set to flex:1, used as child component of Flex Container to create layout with 2 divs of equal size. Used in MainContent and Quote components
import styled from 'styled-components'

const HalfWidthCon = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  ${p => (p.theme.mediaQueries.ipadUp)}{
  padding-top: 0;
}
`
export default HalfWidthCon