import React from 'react'
import styled from 'styled-components'

import {H2} from '../../Atoms/Typography'

const PageTab = styled.div`
  h2{
    display: inline-block;
    padding: .75rem 1.875rem;
    color: ${p => (p.text === "pink" ? p.theme.color.pink 
    : p.text === "burgundy" ? p.theme.color.burgundy 
    : p.text === "gold" ? p.theme.color.gold 
    : ''    )};
    background-color: ${p => (p.color === "pink" ? p.theme.color.pink : p.theme.color.burgundy)};
  }
${p => p.theme.mediaQueries.bigUp}{
  font-family: ${p => p.theme.font.objectives};
  position: absolute;
  left: 0;
  h2{
    padding: 1.875rem .75rem;
    writing-mode: vertical-lr;
    text-orientation: sideways;
    transform: rotate(180deg);
  }
}
`

const Tab = ({title, text, color}) => (
  <PageTab color={color} text={text}>
    <H2>{title}</H2>
  </PageTab>
)

export default Tab