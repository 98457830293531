import React from 'react'
import styled from 'styled-components'

import { H4, Markdown } from '../../Atoms/Typography'
import Icon from '../../Atoms/Icons'

const StatementCon = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 15px 0;
  p:last-child{
    margin-bottom: 0;
  }
  h4{
    padding-bottom: 5px;
    font-family: ${p => p.theme.font.title};
  }
  ${p => p.theme.mediaQueries.smallDown}{
    padding: 10px 0;
  }
`
const ObjectiveStatements = ({title, markdown}) => {
  return(
    <StatementCon>
      <Icon title={title}/>
      <div>
        <H4>{title}</H4>
        <Markdown markdown={markdown} burgundy/>
      </div>
    </StatementCon>
    )
}

export default ObjectiveStatements