import React from 'react'
import styled, {keyframes } from 'styled-components'

import Container from '../../Atoms/Container'
import {Markdown, Body} from '../../Atoms/Typography'
import Number from '../../Atoms/Number'
import {Arrow} from '../../Atoms/Icons/Partials'

import ObjectiveStatements from '../../Molecules/ObjectiveStatements'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`

const Line = styled.div`
  width: 100%;
  height: 20px;
  display: inline-block;
  // background: linear-gradient(to right, ${p => p.theme.color.burgundy} 50%, ${p => p.theme.color.white} 50%);
  // background-size: 200% 100%;
  background: ${p => p.theme.color.white};
  background-position: right bottom;
  transition: all .5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${p => p.theme.mediaQueries.ipadUp}{
    height: 38px;
  }
  svg {
    transition: 0.75 all ease-in-out;
    height: 20px;
    margin-right: 10px;
  }
`
const Title = styled.h3`
  font-family: ${p => p.theme.font.objectives};
  font-weight: 300;
  color: ${(p) => (p.active ? p.theme.color.white : p.theme.color.burgundy)};
  font-size: 1.25rem;
  text-transform: uppercase;
  text-align: right;
  display: inline;
  white-space: nowrap;
  padding-left: 20px;
  padding-right: 1.5rem;
  transition: all .5s ease-in-out;
  ${p => p.theme.mediaQueries.ipadUp}{
    font-size: 2rem;
    padding-right: 2rem;
  }
  ${p => p.theme.mediaQueries.mediumUp}{
    font-size: 2.8rem;
  }
  ${p => p.theme.mediaQueries.hugeUp}{
    padding-right: 10%;
    font-size: 3rem;
  }
`
const TitleCon = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
  div{
    background: ${(p) => (p.active ? p.theme.color.burgundy : p.theme.color.white)};
  }
  svg{
    animation: ${rotate} 0.5s linear;
    transform: ${p => (p.active ? 'rotate(0deg);' : 'rotate(180deg);' )}
    // animation: ${rotate} ${p => (p.active ? '0.5s linear' : '0s linear')}
    path{
       fill: ${p => (p.active ? '' : p.theme.color.burgundy )}
    }
  }
  &:hover{
    div{
      // background-position: left bottom;
      background: ${p => p.theme.color.burgundy};
    }
    h3{
      color: ${p => p.theme.color.white};
    }
    svg{
      path{
        fill: ${p => p.theme.color.white};
      }
    }
  }
`
const Callout = styled(Body)`
  color: ${p => p.theme.color.burgundy};
  font-size: 1rem;
  font-style: italic;
  padding-bottom: 20px;
  ${p => p.theme.mediaQueries.ipadUp}{
    line-height: 1.375rem;
    font-size: 1.125rem;
  }
`
const StyledContainer = styled(Container)`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  ${(props) => (props.active ? `
    position: static;
    opacity: 1;
    transition: opacity .75s linear;` 
    : `
    position: absolute; 
    opacity: 0; 
    z-index: -1;
  `)};
  flex-flow: row nowrap;
  align-items: center;
  ${p => p.theme.mediaQueries.ipadUp}{
    padding-top: 20px;
    padding-bottom: 20px;
  }
`
class Objective extends React.Component {
    constructor(){
    super();
    this.toggleOpen = this.toggleOpen.bind(this);
    this.state = {
      active: false,
    }
  }
  toggleOpen = () => {
    this.setState({ active: !this.state.active });
  }
  render(){
    const {objectiveTitle, position, objectiveDescriptionNode, calloutStatement, foundationalStatementNode, transformationalStatementNode, aspirationalStatementNode } = this.props.objective.node;
  return (
    <li data-aos="fade-up" 
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-delay="50">
      <TitleCon active={this.state.active ? 1 : 0} onClick={this.toggleOpen}>
        <Line>
          <Arrow active={this.state.active ? 1 : 0}/>
        </Line>
        <Title active={this.state.active ? 1 : 0}>{objectiveTitle}</Title>
      </TitleCon>
      <StyledContainer active={this.state.active ? 1 : 0}>
        <Number number={position} />
        <div>
          <Markdown markdown={objectiveDescriptionNode.childMarkdownRemark.html} burgundy/>
          <Callout>{calloutStatement}</Callout>
          <ObjectiveStatements title="Foundational" markdown={foundationalStatementNode.childMarkdownRemark.html} />
          <ObjectiveStatements title="Transformational" markdown={transformationalStatementNode.childMarkdownRemark.html} />
          <ObjectiveStatements title="Aspirational" markdown={aspirationalStatementNode.childMarkdownRemark.html} />
        </div>
      </StyledContainer>
    </li>
    )
  }
}

export default Objective