//Pick random image from DatoCMS image gallery
  export function RandomImage(Images){
    //determine number of items in image gallery
    const length = Images.length;
    //select a random image from gallery
    const random = Math.floor(Math.random() * length);
    //select random image from gallery
    const randomImage = Images[random];
    return randomImage;
  }
