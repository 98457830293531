//Flex container for MainContent and Quote components. Just a styled Container component with display: flex
import styled from 'styled-components'
import Container from '../Container'

import HalfWidthCon from '../../Atoms/HalfWidthCon'

const FlexContainer = styled(Container)`
  display: flex;
  flex-flow: column wrap;  
  ${p => p.theme.mediaQueries.ipadUp}{
    flex-flow: row nowrap;
    ${HalfWidthCon}:nth-child(odd){
      padding-right: 30px;
      align-items: flex-end;
    }
    ${HalfWidthCon}:nth-child(even){
      padding-left: 30px;
      align-items: flex-start;
    }
  }
  ${p => (p.callout ? `align-items: center;`
  :
  ``)}
`
export default FlexContainer