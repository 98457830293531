//Main content sections of the site. Consists of H3 component + markdown
import React from 'react'
import styled from 'styled-components'

import {H3, Markdown} from '../../Atoms/Typography'
import FlexContainer from '../../Atoms/FlexContainer'
import HalfWidthCon from '../../Atoms/HalfWidthCon'

const Emphasized = styled.span`
  color: ${p => (p.burgundy ? p.theme.color.white : p.theme.color.gold )};
  br{
    display: ${p => (p.br ? '' : 'none;' )};
  }
`
const Line = styled.hr`
  border-top: 1px solid ${p => p.theme.color.white};
  border-bottom: 0px none;
  height: 1px;
  width: 100%;
  max-width: 350px;
`
const BottomLine = styled(Line)`
  margin-bottom: 30px;
`

const MainContent = ({text, highlight, br, markdown, burgundy }) => {
  return(
    <FlexContainer>
      <HalfWidthCon data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-delay="50">
        <Line />
          <H3 burgundy={burgundy}>{text}
            <Emphasized br={br} burgundy={burgundy}>
              <br />
              {highlight}
            </Emphasized>
          </H3>
        <BottomLine />
      </HalfWidthCon>
      <HalfWidthCon data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-delay="50">
        <Markdown markdown={markdown} burgundy={burgundy}/>
      </HalfWidthCon>
    </FlexContainer>
  )
}

export default MainContent