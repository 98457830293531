import React from 'react'
import styled from 'styled-components'

import StyledLine from '../../Atoms/StyledLine'
import FlexContainer from '../../Atoms/FlexContainer'
import HalfWidthCon from '../../Atoms/HalfWidthCon'

// import Video from '../../Molecules/Video'
import LottieAnimation from '../../Molecules/Lottie'

const HeroCon = styled.section`
  background: rgb(89,26,42);
  background: linear-gradient(180deg, rgba(89,26,42,1) 0%, rgba(97,33,49,1) 100%);
  min-height: 500px;
  color: ${p => p.theme.color.white};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const Hidden = styled.h1`
  visibility: hidden;
`
const StratPlan = styled.p`
  text-transform: uppercase;
  font-family: ${p => p.theme.font.title};
  font-size: 1rem;
  color: ${p => p.theme.color.gold};
  text-align: centre;
  ${p => p.theme.mediaQueries.ipadUp}{
    text-align: left;
  }
  ${p => p.theme.mediaQueries.mediumUp}{
    font-size: 1.25rem;
  }
  span {
    color: ${p => p.theme.color.white};
  }
`
const Container = styled(FlexContainer)`
  padding-top: 0px;
  ${p => p.theme.mediaQueries.ipadUp}{
    padding-top: 0px;
  }
`
const Hero = () => {
  return (
    <HeroCon>
      {/* <Video /> */}
      <LottieAnimation />
      <Hidden>Further Together</Hidden>
      <Container>
        <HalfWidthCon></HalfWidthCon>
        <HalfWidthCon>
          <StratPlan data-aos="fade-up" 
          data-aos-duration="1000"
          data-aos-easing="ease-in-out">Cambrian College Strategic Plan <br /><span>2020 – 2025</span></StratPlan>
        </HalfWidthCon>   
      </Container>
      <StyledLine />
    </HeroCon>
  )
}

export default Hero