import React from 'react'
import styled from 'styled-components'

import {Markdown} from '../../Atoms/Typography'
import {QuoteIcon} from '../../Atoms/Icons/Partials'

const QuoteCon = styled.span`
  display: flex;
  flex-flow: column nowrap;
  ${p => p.theme.mediaQueries.bigUp}{
    padding-left: 30px;
  }
  ${p => p.theme.mediaQueries.xlUp}{
    padding-left: 0;
  }
`
const QuotationMark = styled(QuoteIcon)`
  ${p => (p.close ? 'transform: rotate(180deg); width: 30px; align-self: flex-end;' : 'width: 50px;')};
  path{
    fill: ${ p => (p.burgundy ? p.theme.color.burgundy : p.theme.color.gold)};
  }
`
const Attribution = styled.p`
  font-family: ${p => p.theme.font.body};
  font-style: italic;
  color: ${ p => (p.burgundy ? p.theme.color.burgundy : p.theme.color.white)};
  padding: 20px 0 30px 20px;
  font-weight: 100;
  ${p => p.theme.mediaQueries.largeUp}{
    padding-left: ${ p => (p.burgundy ? '60px' : '20px')};
  }
`

const Quote = ({markdown, quote, burgundy, attribution}) => {
  return(
    <QuoteCon>
      <QuotationMark burgundy={burgundy ? 1 : 0}/>
      <Markdown markdown={markdown} quote={quote} burgundy={burgundy ? 1 : 0}/>
      <QuotationMark burgundy={burgundy ? 1 : 0} close="true"/>
      <Attribution burgundy={burgundy ? 1 : 0}>– {attribution}</Attribution>
    </QuoteCon>
  )
}

export default Quote