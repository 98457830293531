import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from'gatsby'

import FlexContainer from '../../Atoms/FlexContainer'
import HalfWidthCon from '../../Atoms/HalfWidthCon'
import StyledLine from '../../Atoms/StyledLine'
import Section from '../../Atoms/Section'

import ProcessGraphic from '../../Molecules/ProcessGraphic'
import Quote from '../../Molecules/Quote'
import Tab from '../../Molecules/Tab'

const ProcessCon = styled(Section)`
  background: rgb(247,154,96);
  background: linear-gradient(360deg, rgba(247,154,96,1) 0%, rgba(184,91,92,1) 100%);
`

const Process = () => {
  const data = useStaticQuery(graphql`
  query ProcessQuery {
    process: datoCmsProcess{
      sectionTitle
      quoteNode{
        childMarkdownRemark{
          html
        }
      }
      attribution
    }
  }
  `)
  return(
    <ProcessCon id="process">
      <Tab color="burgundy" text="pink" title={data.process.sectionTitle}/>
      <FlexContainer>
        <HalfWidthCon data-aos="fade-up" 
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-delay="50">
          <Quote markdown={data.process.quoteNode.childMarkdownRemark.html} attribution={data.process.attribution} burgundy quote/>
        </HalfWidthCon>
        <HalfWidthCon data-aos="fade-up" 
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-delay="50">
          <ProcessGraphic />
        </HalfWidthCon>
      </FlexContainer>
      <StyledLine burgundy />
    </ProcessCon>
  )
}

export default Process