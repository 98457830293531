import * as React from "react"

function SvgComponent(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 50.38 37.34" {...props}>
      <path
        d="M20.74 5.83q-10 4.86-10 11.42a11 11 0 017.05 3.45 9.71 9.71 0 012.75 6.84 9.7 9.7 0 01-2.71 7 9 9 0 01-6.76 2.83 10.15 10.15 0 01-7.82-3.68A13 13 0 010 24.71Q0 8.9 17.58 0zm29.64 0q-9.89 4.86-9.88 11.42a11 11 0 017 3.45 9.75 9.75 0 012.75 6.84 9.79 9.79 0 01-2.67 7 8.86 8.86 0 01-6.73 2.83A10.25 10.25 0 0133 33.66a12.87 12.87 0 01-3.32-8.95Q29.65 8.9 47.3 0z"
        fill="#702d3d"
      />
    </svg>
  )
}

export default SvgComponent

