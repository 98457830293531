import React from 'react'
import styled, { css } from 'styled-components'

const NumberCon = styled.div`
  padding: 0 100px;
  ${p => p.theme.mediaQueries.smallDown}{
    display: none;
  }
`
const NumberFont = css`
  font-family: ${p => p.theme.font.objectives};
  color: #ffb217;
  font-size: 22rem;
  font-weight: bold;
`
const StyledNumber = styled.p`
  ${NumberFont};
`

const Number = ({number}) => {
  return(
    <NumberCon>
      <StyledNumber>{number}</StyledNumber>
    </NumberCon>
  )
}

export default Number