import styled from 'styled-components'
import Img from 'gatsby-image'

const Chevron = styled(Img)`
  width: 100%;
  ${ p => p.theme.mediaQueries.smallDown}{
    width: 80vw;
    margin: 0 auto;
    margin-bottom: 30px;
  }
`

export default Chevron;