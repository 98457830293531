import * as React from "react"
import styled from 'styled-components'

const SVG = styled.svg`
  width: 65px;
  height: auto;
  ${p => p.theme.mediaQueries.ipadUp}{
    width: 120px;
  }
  ${p => p.theme.mediaQueries.largeUp}{
    width: 165px;
  }
`

function SvgChevron(props) {
  return (
    <div data-aos="fade-up" 
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-delay="50">
      <SVG data-name="Layer 1" viewBox="0 0 88.49 124.12" {...props}>
        <path
          d="M88.49 0L44.36 30.25 0 0v37.86l44.36 31.37 44.13-31.37zm0 54.89L44.36 85.13 0 54.89v37.86l44.36 31.37 44.13-31.37z"
          fill="#F4A912"
        />
      </SVG>
    </div>
  )
}

export default SvgChevron

