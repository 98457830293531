import * as React from "react"

function Diamond(props) {
  return (
    <svg
      id="Diamond_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 55.36 55.36"
      {...props}
    >
      <defs>
        <style>{".Diamond_svg__cls-1{fill:#702d3e}"}</style>
      </defs>
      <path
        className="Diamond_svg__cls-1"
        transform="rotate(45 27.686 27.678)"
        d="M17.93 17.93h19.49v19.49H17.93z"
      />
      <path
        className="Diamond_svg__cls-1"
        d="M27.68 55.36a27.68 27.68 0 1127.68-27.68 27.71 27.71 0 01-27.68 27.68zm0-54A26.35 26.35 0 1054 27.68 26.38 26.38 0 0027.68 1.33z"
      />
    </svg>
  )
}

export default Diamond

