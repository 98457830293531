import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from'gatsby'

import Section from '../../Atoms/Section'

import Tab from '../../Molecules/Tab'
import MainContent from '../../Molecules/MainContent'

const ApproachCon = styled(Section)`
  background: rgb(112,45,62);
  background: linear-gradient(180deg, rgba(112,45,62,1) 0%, rgba(184,91,92,1) 100%);
  // background: rgb(184,91,92);
  // background: linear-gradient(0deg, rgba(184,91,92,1) 0%, rgba(122,43,65,1) 100%);
`

const Approach = () => {
  const data = useStaticQuery(graphql`
  query ApproachQuery {
    approach: datoCmsApproach{
      sectionTitle
      sectionBody1Node{
        childMarkdownRemark{
          html
        }
      }
      sectionBody2Node{
        childMarkdownRemark{
          html
        }
      }
    }
  }
  `)
  return(
    <ApproachCon id="approach">
      <Tab color="pink" text="burgundy" title={data.approach.sectionTitle}/>
      <MainContent text="One " highlight="goal" markdown={data.approach.sectionBody1Node.childMarkdownRemark.html} />
      <MainContent text="Unlimited" highlight="Ways to Lead" br markdown={data.approach.sectionBody2Node.childMarkdownRemark.html} />
    </ApproachCon>
  )
}

export default Approach